.leave-start-form {
  .leave-evidence-enter {
    display: block;
    height: 1px;
    opacity: 0;
  }
  .leave-evidence-enter-active {
    opacity: 1;
    height: 230px;
    transition: all 200ms ease-in-out;
    will-change: opacity, height;
  }
  .leave-evidence-exit {
    height: 230px;
  }
  .leave-evidence-exit-active {
    opacity: 0;
    height: 1px;
    transition: all 200ms ease-in-out;
    will-change: opacity, height;
  }
  .leave-evidence-exit-done {
    display: none;
  }

  .leave-business-enter {
    display: block;
    height: 1px;
    opacity: 0;
  }
  .leave-business-enter-active {
    opacity: 1;
    height: 148px;
    transition: all 200ms ease-in-out;
    will-change: opacity, height;
  }
  .leave-business-exit {
    height: 148px;
  }
  .leave-business-exit-active {
    opacity: 0;
    height: 1px;
    transition: all 200ms ease-in-out;
    will-change: opacity, height;
  }
  .leave-business-exit-done {
    display: none;
  }
}